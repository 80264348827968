var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.teams,"headers":[
      { text: 'Mannschaft', value: 'team.name', sortable: false },
      { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' } ].concat( _vm.disc.map(function (d) { return ({ text: d.shortName, value: d._id, sortable: false }); })
    ),"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.team.name))]),_c('td',{staticStyle:{"text-align":"center"}},[_c('base-edit-dialog',{attrs:{"value":item.order,"id":item.team._id,"label":"Reihenfolge"},on:{"input":function (ref) {
        var value = ref.value;
        var id = ref.id;

        return _vm.setorder(id, parseInt(value));
    }}})],1),_vm._l((_vm.disc),function(d){return _c('td',{key:d._id},[(item._startdiscipline === d._id)?_c('v-icon',[_vm._v("far fa-check")]):_c('v-btn',{attrs:{"text":"","fab":"","x-small":""},on:{"click":function($event){return _vm.setstartdiscipline(item.team._id, d._id)}}},[_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("far fa-xmark")])],1)],1)})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }